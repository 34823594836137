
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import { useStore } from "vuex";
import * as Yup from "yup";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import { useI18n } from "vue-i18n";
import { EXCEPTION_TYPE } from "@/core/Constant";
import { CUSTOM_ERROR_MESSAGES, STORE, SWAL_MESSAGES } from "@/domain/Constant";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    // const router = useRouter();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);

    const passwordFieldType = ref("password");

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email(t(CUSTOM_ERROR_MESSAGES.email_invalid))
        .required(t(CUSTOM_ERROR_MESSAGES.email_required))
        .label("Email"),
      password: Yup.string()
        .min(
          EXCEPTION_TYPE.MIN_PASSWORD_LENGTH,
          t(CUSTOM_ERROR_MESSAGES.password_min)
        )
        .required(t(CUSTOM_ERROR_MESSAGES.password_required))
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      await store.dispatch(STORE.CONTROLLERS);

      const userController = store.state.ControllersModule.userController;
      if (!userController) {
        throw new Error("userController is not available in Vuex store.");
      }

      try {
        const response = await userController.login({
          email: values.email,
          password: values.password,
        } as LoginModel);

        if (response.isSuccess) {
          localStorage.setItem("email", values.email);
          swalNotification.success(
            t(SWAL_MESSAGES.LOGIN_SUCCESS_MESSAGE),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        } else {
          swalNotification.error(
            response.error.cause.cause +
              t(SWAL_MESSAGES.ERROR_CODE) +
              response.error.code +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          /*
          swalNotification.error(
            response.error.cause.cause + response.error + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          */
        }
      } catch (e) {
        console.log(e);
        swalNotification.error(
          "vue error",
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      togglePasswordVisibility,
      passwordFieldType,
    };
  },
});
