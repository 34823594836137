<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t("signInTitle") }}</h1>
        <!--end::Title-->

        <!--
        <div class="text-gray-400 fw-bold fs-4">
          New Here?

          <router-link to="/sign-up" class="link-primary fw-bolder">
            Create an Account
          </router-link>
        </div>
        -->
      </div>
      <!--begin::Heading-->

      <div class="mb-10 bg-light-info p-8 rounded">
        <div class="text-info">
          {{ $t("adminPassword") }} <br />
          <!--
          Use account <strong>admin@demo.com</strong> and password
          <strong>demo</strong> to continue.
          -->
        </div>
      </div>

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">{{
          $t("email")
        }}</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="email"
          autocomplete="off"
        />
        <!--value="busecalli25@gmail.com"-->
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-6">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0">{{
            $t("password")
          }}</label>
          <!--end::Label-->
        </div>
        <!--end::Wrapper-->

        <div class="col-lg-12 fv-row">
          <div class="input-group">
            <Field
              class="form-control form-control-lg form-control-solid"
              :type="passwordFieldType"
              name="password"
              autocomplete="off"
            />
            <div class="form-control-solid">
              <button
                type="button"
                class="btn btn-icon btn-sm btn-active-light-primary ms-2 mt-2"
                @click="togglePasswordVisibility"
              >
                <i class="bi bi-eye"></i>
                <!-- Icon for visible password -->
                <i class="bi bi-eye-slash d-none"></i>
                <!-- Icon for hidden password -->
              </button>
            </div>
          </div>
        </div>

        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <div class="fv-row mb-4">
        <router-link to="/forgot-password" class="link-primary fs-6 fw-bolder">
          {{ $t("forgotPassword") }}
        </router-link>
      </div>
      <!--end::Input group-->
      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> {{ $t("login") }}</span>

          <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
        <!--
        <div class="text-center text-muted text-uppercase fw-bolder mb-5">
          or
        </div>-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/presentation/store/enums/StoreEnums";

import { useStore } from "vuex";
import * as Yup from "yup";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { LoginModel } from "@/domain/authentication/loginUser/model/LoginModel";
import { useI18n } from "vue-i18n";
import { EXCEPTION_TYPE } from "@/core/Constant";
import { CUSTOM_ERROR_MESSAGES, STORE, SWAL_MESSAGES } from "@/domain/Constant";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    // const router = useRouter();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);

    const passwordFieldType = ref("password");

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string()
        .email(t(CUSTOM_ERROR_MESSAGES.email_invalid))
        .required(t(CUSTOM_ERROR_MESSAGES.email_required))
        .label("Email"),
      password: Yup.string()
        .min(
          EXCEPTION_TYPE.MIN_PASSWORD_LENGTH,
          t(CUSTOM_ERROR_MESSAGES.password_min)
        )
        .required(t(CUSTOM_ERROR_MESSAGES.password_required))
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      await store.dispatch(STORE.CONTROLLERS);

      const userController = store.state.ControllersModule.userController;
      if (!userController) {
        throw new Error("userController is not available in Vuex store.");
      }

      try {
        const response = await userController.login({
          email: values.email,
          password: values.password,
        } as LoginModel);

        if (response.isSuccess) {
          localStorage.setItem("email", values.email);
          swalNotification.success(
            t(SWAL_MESSAGES.LOGIN_SUCCESS_MESSAGE),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
        } else {
          swalNotification.error(
            response.error.cause.cause +
              t(SWAL_MESSAGES.ERROR_CODE) +
              response.error.code +
              response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          /*
          swalNotification.error(
            response.error.cause.cause + response.error + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          */
        }
      } catch (e) {
        console.log(e);
        swalNotification.error(
          "vue error",
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      togglePasswordVisibility,
      passwordFieldType,
    };
  },
});
</script>
